.input-stats-screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--st-height-diff) !important;
}

.title-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5em;
}

.dashboard-button {
  margin-left: 2em;
}

.underHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}

.chart-view {
  width: 80%;
}

.input-viewer {
  width: 20%;
  overflow: auto;
}

.input-fields {
  height: 99vh;
}

@media only screen and (max-width: 1200px) {
  .underHeader {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: var(--st-height-diff);
    justify-content: space-between;
  }

  .chart-view {
    width: 100%;
    height: 60%;
  }

  .input-viewer {
    width: 100%;
    height: 40%;
  }

  /* .input-fields {
    height: auto;
  } */
}

@media only screen and (max-width: 680px) {
  .underHeader {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: var(--st-height-diff);
  }

  .chart-view {
    visibility: hidden;
    height: 0;
  }

  .input-viewer {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  .dashboard-button {
    display: none;
  }
}
