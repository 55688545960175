.container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
  pointer-events: all;
}

.container__item {
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  position: relative;
  overflow: visible;
}

.container__item:hover {
}

.item__label {
  display: flex;
  align-items: center;
  gap: 2px;
  font-weight: 600;
  color: var(--primary);
}
