@import '../../../../../../src/reusableStyles/ReusableModalStyles.module.scss';

.help-desk-modal {
  @include modal;
  width: 650px;
  height: 300px;
  border: 1px solid var(--medium-gray);
  box-shadow: var(--gentle-shadow);
  color: var(--label-color);

  .modal__body {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    padding: 0;
  }
}

.modal__header {
  & h3 {
    margin: 0;
  }
}

.modal__close:hover {
  color: var(--dark-gray);
}