.items-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.item {
  border-bottom: solid 1px var(--light-gray);
  font-weight: bold;
  padding: 1rem 0rem;
  cursor: pointer;
  font-size: 15px;

  &:hover {
    background-color: var(--light-gray);
  }

  & a {
    color: var(--bold-font-color);
  }
}

.item__label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
}

.expand {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.item__sub-items {
  display: flex;
  flex-direction: column;
}

.sub-item {
  padding: 0.5rem 1rem;

  & span {
    color: var(--bold-font-color);
    font-weight: 500;
  }

  &:hover {
    background-color: var(--medium-gray);
  }
}
