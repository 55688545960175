.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  width: 350px;

  & h3 {
    font-weight: bold;
    margin: 0;
    padding: 0.5rem;
    font-size: 16px;
  }
}

.container__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filters {
  list-style-type: none;
  padding: 0.5rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  & li {
    cursor: pointer;
    padding: 8px;
    border-radius: 1.2rem;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
    color: var(--bold-font-color);
  }
  & li:hover {
    background-color: var(--light-gray);
  }
}

.filter--focus {
  color: focus;
  background-color: var(--focus-secondary);
  &:hover {
    background-color: var(--focus-secondary) !important;
  }
}

.actions {
  background-color: white;
  padding: 8px;
  list-style-type: none;
  border-radius: 0.5rem;
  border: solid 1px var(--light-gray);

  & li {
    padding: 8px;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    font-weight: 600;
  }
  & li:hover {
    background-color: var(--light-gray);
  }
}
