.item-list {
  position: absolute;
  top: 60px;
  left: 0;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: white;
  width: fit-content !important;
  display: flex;
  flex-direction: column;
  box-shadow: var(--gentle-shadow);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;

  & li {
    padding: 0.5rem;
    color: var(--primary);
    display: block;
    width: fit-content;
    white-space: nowrap;
    width: 100%;
    position: relative;

    & a {
      font-weight: 600;
      color: var(--primary);
    }
  }

  & li:hover {
    background-color: var(--light-gray);

    transition-duration: 0ms;
  }
}
