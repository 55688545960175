.progress_modal_container {
  position: fixed;
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  gap: 10px;
  transition: all 0.6s ease-out;
  width: fit-content;
  padding: 1rem;
}
