.cart-item {
  display: flex;
  gap: 5px;
  font-size: 0.75rem;
  height: 40px;
}

.item__image {
  background-color: var(--light-gray);
  width: 60px;
  flex-grow: 1;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.item__body {
  flex-grow: 1;
}

.body__item-name {
  width: 200px;
  display: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item__price {
  justify-content: flex-end;
  font-weight: bold;
  text-align: right;
  flex: 0 0 30px;
}

.item__remove {
  color: var(--dark-gray);
  margin-left: 10px;

  &:hover {
    cursor: pointer;
    color: var(--medium-gray);
  }
}
