.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.full_bar {
  width: 100%;
  background-color: var(--medium-gray);
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.bar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;

  & label {
    margin: 0;
  }
}

.complete_bar {
  height: 100%;
  background-color: var(--focus);
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: width 0.5s ease-in-out;

}

.percentage {
  padding: 0.3rem;
  color: white
}