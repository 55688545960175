.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: grid;
  align-items: center;
  justify-items: center;
  z-index: 50;
  overflow: auto;
  /* 09/18/24 edit for purchase address checkout */
}