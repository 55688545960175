@import '../../../../../../src/reusableStyles/ReusableModalStyles.module.scss';

.course-review {
  @include modal;
  width: 650px;
  border: 1px solid var(--medium-gray);
  box-shadow: var(--gentle-shadow);
  color: var(--label-color);
  max-height: 800px;

  & .modal__body {
    max-height: 650px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    ::-webkit-scrollbar {
      width: 6px;
    }
  }

  & video {
    height: 300px;
  }
}

.modal__header {
  margin: 0;
  font-weight: bold;

  & h3 {
    margin: 0;
  }
}

.modal__close {
  cursor: pointer;
  font-size: 2rem;
  color: #585858;
  font-weight: bolder;
  line-height: 1;

  &:hover {
    color: var(--dark-gray);
  }
}

.body__content-list {
  height: 400px;
  overflow-y: auto;
  border: 1px solid var(--medium-gray);
  border-radius: 5px;
  padding: 0.5rem 0 0.5rem 1rem;
}



.list__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-weight: bold;
  margin-right: 10px;

  &:hover {
    background-color: var(--medium-gray);
    border-radius: 5px;
    cursor: pointer;
  }
}

.list-module {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 0.5rem;
}

.footer__price {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
}