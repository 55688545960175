@import url('https://fonts.googleapis.com/css?family=Prosto+One');
@import url('https://fonts.googleapis.com/css?family=Cedarville+Cursive');

@import url('https://fonts.googleapis.com/css2?family=Exo:wght@600&family=Rubik:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rufina&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Commissioner&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo&family=Vollkorn&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Shadow&display=swap');

body {
  font-size: 14px;
  box-sizing: border-box;
  background-color: rgb(240, 242, 245);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500;
}

:root {
  --primary: #070738;
  --gentle-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%), 0 1px 3px 0 rgb(0 0 0 / 6%);
  --heavy-shadow: -2px 3px 2px 0 rgb(0 0 0 / 20%),
    2px 1px 1px 2px rgb(0 0 0 / 12%);
  --bord-rad: 5px;
  --secondary: rgb(232, 233, 240);
  --bold-font-color: #4a5073;
  --label-color: #303236;
  --focus: #48a1ff;
  --focus-secondary: rgb(204, 229, 255);
  --light-gray: #ededed;
  --medium-gray: #ccc;
  --dark-gray: #8a8a8a;
  --st-height-diff: calc(100vh - 59px);
  --brilliant: #ff8800ec;
  --warning: rgb(203, 50, 0);
  --warning-secondary: rgb(228, 138, 126);
  --clamp-width: clamp(400px, 90%, 1400px);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

/*fixes info window in google maps (removes empty scrollbar tracks)*/
.gm-style-iw-d {
  overflow: hidden !important;
  padding: 0 12px 12px 0;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7a7a7a;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
  --clamp-width: clamp(400px, 90%, 1400px);
  --money: #68bb59;
}

.new-feature-alert {
  color: rgb(6, 72, 255);
  font-weight: bold;
}

/* Was Homenav css */
.header {
  background-color: white;
  height: 34px;
}

.bounds {
  margin-top: 2%;
  margin-left: 10%;
  margin-right: 10%;
}

.tutorial-popover .ant-popover {
  z-index: 11;
}

.cart-scroll::-webkit-scrollbar {
  width: 9px;
}

.cart-scroll {
  scrollbar-width: thin;
  scroll-behavior: smooth;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.cart-scroll::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

.cart-scroll::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 1px solid var(--scrollbarBG);
}

#cardForm .ant-form-item-label>label {
  color: #666668;
  /* font-family: 'Exo', sans-serif; */
  font-size: 14px;
}

.form-checkbox .ant-checkbox .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  /* background-color: #265380; */
}

.form-checkbox .ant-checkbox-inner::after {
  width: 10px;
  height: 18px;
}

.ant-layout-sider-zero-width-trigger {
  z-index: 200;
}

@media (max-width: 450px) {
  .checkout-steps.ant-steps-horizontal.ant-steps-label-horizontal {
    display: flex;
    flex-direction: row;
  }

  .checkout-steps.ant-steps-horizontal.ant-steps-label-horizontal>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
    display: none;
  }
}

/* for star loading icon */
.pulsing-5 {
  width: 80px;
  height: 80px;
  position: relative;
}

.pulsing-5:before,
.pulsing-5:after {
  content: '';
  position: absolute;
  inset: 0;
  background: #1f3252;
  box-shadow: 0 0 0 50px;
  clip-path: polygon(50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%);
}

.pulsing-5:after {
  animation: pl5 1s infinite;
  transform: perspective(300px) translateZ(0px);
}

@keyframes pl5 {
  to {
    transform: perspective(300px) translateZ(150px);
    opacity: 0;
  }
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(0, 0, 0);
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 1000px;
  height: 1000px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  left: -30px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgb(46, 64, 87);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 32px;
  animation: lds-ellipsis1 0.4s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 32px;
  animation: lds-ellipsis2 0.4s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 128px;
  animation: lds-ellipsis2 0.4s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 224px;
  animation: lds-ellipsis3 0.4s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(96px, 0);
  }
}