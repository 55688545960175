.danger {
  width: 200px;
  background-color: var(--secondary);
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-weight: bold;
  opacity: 1;
  transition: ease-in-out 0.3s;
  border: 1px solid rgb(184, 8, 8);
  padding: 0.5rem;
  color: rgb(184, 8, 8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
}

.danger:hover {
  background-color: rgb(184, 8, 8);
  color: var(--secondary);
  outline: 1px solid rgb(184, 8, 8);
  cursor: pointer;
}

.pretty {
  width: 200px;
  background-color: var(--primary);
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-weight: bold;
  opacity: 1;
  transition: ease-in-out 0.3s;
  border: none;
  padding: 0.5rem;
  color: var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
}

.pretty:hover {
  background-color: var(--secondary);
  color: var(--primary);
  outline: 1px solid var(--primary);
  cursor: pointer;
}

.pretty:disabled {
  background-color: #ccc;
  color: white;
  cursor: not-allowed;
  outline: none;
}

.danger:disabled {
  background-color: #ccc;
  color: white;
  cursor: not-allowed;
  outline: none;
}