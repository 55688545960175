.progress_modal {
  position: relative;
  z-index: 12;
  padding: 0.8rem;
  display: flex;
  background-color: var(--light-gray);
  border: 1px solid var(--medium-gray);
  box-shadow: var(--gentle-shadow);
  border-radius: 1rem;
  width: 280px;

}

.close_button {
  position: absolute;
  transform: translateX(40%) translateY(-40%);
  font-size: 1.5rem;
  right: 0;
  top: 0;
}

.close_button:hover {
  cursor: pointer;
}