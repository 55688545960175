.inputs-form {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1200px) {
  .inputs-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
