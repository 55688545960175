.notification {
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 600;
}

.notification__options {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.option__button {
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  border: 1px solid var(--medium-gray);
}

.option__button:hover {
  background-color: var(--focus);
}

.notification__body {
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;

  .notification__edit {
    visibility: hidden;
  }
}

.notification__body:hover {
  background-color: var(--light-gray);

  .notification__edit {
    visibility: visible;
  }
}

.notification__pic {
  flex-basis: 56px;
  flex-shrink: 0;
  height: 56px;
  overflow: hidden;
  border-radius: 50%;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 12px;
  & img {
    width: 100%;
    height: 100%;
  }
}

.notification__content {
  margin-top: 8px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.notification__time {
  color: var(--focus);
}

.read-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  justify-content: center;
}

.read-icon {
  visibility: hidden;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--focus);
}

.read-icon--show {
  visibility: visible;
}

.notification__edit {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 30px;
  height: 30px;
  padding-top: 3px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--medium-gray);
}

.notification__edit:hover {
  background-color: var(--focus-secondary);
}

.bold {
  font-weight: bold;
}

.actions {
  background-color: white;
  padding: 8px;
  list-style-type: none;
  border-radius: 0.5rem;
  border: solid 1px var(--light-gray);

  & li {
    padding: 8px;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    font-weight: 600;
  }
  & li:hover {
    background-color: var(--light-gray);
  }
}
