.cart {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart__header {
  font-weight: bold;
  margin: 0;
  padding: 0.5rem;
  background-color: var(--light-gray);
}

.cart__body {
  padding: 0 1rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.cart__body::-webkit-scrollbar {
  display: none;
}

.cart__total {
  display: flex;
  justify-content: space-between;
  background-color: var(--light-gray);
  padding: 0 1rem;
  font-weight: bold;
  align-items: center;
  padding: 0.5rem;

  & span:nth-child(2) {
    font-size: 1.5rem;
  }
}

.empty-cart {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart__button {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.button__pretty-button {
  width: 150px;
  height: 30px;
  font-size: 0.75rem;
  bottom: 0;
}
