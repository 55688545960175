@import '../../../../../../src/reusableStyles/ReusableModalStyles.module.scss';

.recording-modal {
  @include modal;
  width: 650px;
  border: 1px solid var(--medium-gray);
  box-shadow: var(--gentle-shadow);
  color: var(--label-color);
  max-height: 600px;
  min-height: 0;
  overflow: hidden;
  box-sizing: border-box;

  .modal__body {
    display: flex;
    flex-direction: row;
    padding: 0;
    overflow: hidden;
  }
}

.modal__header {
  & h3 {
    margin: 0;
  }

  & div {
    cursor: pointer;
    font-size: 2rem;
    color: #585858;
    font-weight: bolder;
    line-height: 1;

    &:hover {
      color: var(--dark-gray);
    }
  }
}

.video {
  width: 100%
}

.body__media {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  & audio {
    width: 100%;
  }
}

.body__date-list {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid var(--medium-gray);
}

.list__header {
  text-align: center;
  width: fit-content;
  margin: 0;
  padding: 0.5rem;
}

.list__body {
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  width: 100%;
  text-align: center;
  color: var(--dark-gray);
}

.list__selected {
  font-weight: bold;
  color: var(--focus);
}

.list__date:hover {
  cursor: pointer;
  color: var(--focus);
}

.modal__footer {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}