.search-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  width: 600px;
  color: var(--dark-gray);
}

@media screen and (max-width: 500px) {
  .search-container {
    width: 100%;
  }
}

.search__input {
  padding: 0.5rem;

  div {
    border-radius: 30px;
    border: 2px solid var(--light-gray);
    display: flex;
    font-size: 2em;
    align-items: center;
    padding-left: 10px;

    &:hover {
      background-color: var(--light-gray);

      input {
        background-color: var(--light-gray);
      }
    }
  }

  div:focus-within {
    background-color: var(--light-gray);

    input {
      background-color: var(--light-gray);
    }
  }

  input {
    border: none;
    outline: none;
    width: 100%;
    padding-left: 10px;
    border-radius: 30px;
    overflow: hidden;
    font-size: 14px;
    padding: 0.5rem;
    font-weight: bold;
  }
}

.result__category {
  width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--light-gray);
}

.result__list {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.variableSizeList {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.variableSizeList::-webkit-scrollbar {
  display: none;
  width: 0;
}

.hightlight {
  background-color: yellow;
}

@mixin categoryItem {
  padding: 0.5rem;
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    padding: 0.5rem 0;
    font-size: 11px;
  }
}

.category__item {
  @include categoryItem;

  &:hover {
    cursor: pointer;
    color: var(--focus);
  }
}

.category__selected {
  @include categoryItem;
  color: var(--focus);
}

.list__no-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
}
