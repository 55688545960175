.modal {
  width: 90%;
  max-width: 1000px;
  height: fit-content;
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
  padding: 1rem;
}

.modal__body {
  padding: 1rem;
  text-align: center;
}

.modal_close {
  position: absolute;
  right: 8px;
  top: 4px;
  width: 20px;
  height: 20px;

  :hover {
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--light-gray);

  }
}