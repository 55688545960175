@media only screen and (max-width: 1000px) {
  .CRM-modal {
    width: 90% !important;
  }
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
