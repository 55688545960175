@mixin error-display() {
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 2.5rem auto;
  overflow: hidden;
  background-color: white;
  box-shadow: var(--gentle-shadow);
  width: var(--clamp-width);
  height: clamp(400px, 30vw, 800px);
  box-shadow: var(--gentle-shadow);

  & h4 {
    color: rgba(99, 99, 99, 0.6);
    font-size: 1.25rem;
  }
}

@mixin modal {
  min-width: 500px;
  min-height: 500px;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  @media screen and (max-width: 650px) {
    min-width: 0;
    max-width: 90%;
    width: 90%;
  }

  .modal__header {
    padding: 0.75rem;
    border-bottom: solid;
    border-width: 1px;
    border-color: lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &h3 {
      margin: 0;
      font-weight: bolder;

      color: #585858;
    }
  }

  .modal__close {
    cursor: pointer;
    font-size: 32px;
    color: #585858;
    font-weight: bolder;
    line-height: 1;
    cursor: pointer;
  }

  .modal__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 1.5rem;
  }
}