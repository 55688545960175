.result-item {
  display: flex;
  padding: 10px;
  gap: 10px;
  width: 100%;
  height: fit-content;
  color: var(--label-color);
}

.clickable-card:hover {
  cursor: pointer;
}

.item__image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary);
}

.item__list {
  width: 100%;
}

.item__name {
  font-weight: 500;
}

.item__recording {
  display: flex;
  gap: 20px;
  margin-left: 10px;
}

.recording__tags {
  display: flex;
  gap: 5px;
}

.tags__item {
  font-size: 10px;
  border: 1px solid var(--medium-gray);
  border-radius: 5px;
  background-color: var(--secondary);
}

.recording__date {
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    cursor: pointer;
    color: var(--focus);
  }
}

.card {
  border-bottom: 1px solid var(--light-gray);

  &:hover {
    background-color: var(--light-gray);
  }
}

.item__load-more {
  font-weight: bold;
  font-style: italic;
  margin-left: 10px;
  color: var(--dark-gray);

  &:hover {
    color: var(--focus);
    cursor: pointer;
  }
}

.item__section {
  display: flex;
  flex-direction: column;
}

.item__section-name {
  margin-left: 5px;
}

.item__module-name {
  margin-left: 15px;
}

.item__image-display {
  position: relative;
  width: 50px;
  height: 50px;
}

.image-display__image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary);
}

.image-display__icon {
  display: flex;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
  width: 1.75rem;
  height: 1.75rem;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--medium-gray);
}

.result-item__divider {
  width: 100%;
  height: 1px;
  border-width: 0;
  margin: 0;
  background-color: var(--light-gray);
}

.locked-card {
  background-color: rgba(120, 120, 120, 0.349);
  color: var(--label-color);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 3;

  &:hover {
    cursor: pointer;
  }
}

b {
  border-radius: 5px;
  padding: 2px;
}
