.formItem-div {
  height: 100px;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .formItem-div {
    width: 30%;
    min-width: 215px;
    margin: 0.25em;
  }
}

@media only screen and (max-width: 680px) {
  .formItem-div {
    width: 100%;
    height: 100px;
  }
}
