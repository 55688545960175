.toggle-button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
}

.toggle-button:hover {
  background-color: var(--light-gray);
}
