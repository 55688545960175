.nav {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: 0rem 1rem;
  position: relative;
  height: 60px;
  font-size: 14px;
  max-width: 100vw;
  min-width: 0;
}

.logo {
  cursor: pointer;

  & img {
    height: 60px;
    padding: 5px 0px;
  }
}

.center-area {
  flex-grow: 1;
  margin-left: 1rem;
  margin-right: 1rem;
}

.icons-group {
  display: flex;
  margin-left: auto;
  flex-basis: content;
  align-items: center;
  gap: 0.75rem;
}

.icons-group__small {
  position: relative;
  display: flex;
  font-size: 20px;
  color: var(--primary);
  cursor: pointer;
}

.icon-count {
  position: absolute;
  top: -7.5px;
  right: -7.5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  background-color: var(--focus);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.desktop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.desktop--hidden {
  visibility: hidden;
}

.desktop__items {
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
}

.icons-group__large {
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;

  & img {
    width: 100%;
    height: 100%;
  }

  & div {
    box-shadow: inset 0 0 1px 1px var(--primary);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.icons-group__large:hover {
  & div {
    background: rgb(255, 255, 255);
    background: linear-gradient(45deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.62) 100%);
  }
}

.utility-box {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  max-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 100px;
  width: fit-content;
  border: solid 1px var(--light-gray);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  border-bottom-left-radius: 1rem;
  box-sizing: border-box;
}

.tray {
  position: fixed;
  top: 60px;
  left: 0;
  background-color: white;
  max-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 60px);
  width: fit-content;
  min-width: 350px;
  border: solid 1px var(--light-gray);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  border-bottom-left-radius: 1rem;
  z-index: 124;
}

.utility-box__content {
  position: relative;
}

.utility-box::-webkit-scrollbar {
  display: none;
}

.hamburger {
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 8px;
  margin-right: 1rem;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: var(--light-gray);
  }
}

@media screen and (max-width: 650px) {
  .tray {
    width: 100vw;
  }

  .utility-box {
    width: 100%;
  }

  .nav {
    padding: 0rem 0.5rem;
  }

  .icons-group {
    gap: 0.5rem;
  }

  .center-area {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .logo {
    cursor: pointer;

    & img {
      height: 40px;
      padding: 5px 0px;
    }
  }
}